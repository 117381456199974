import { Grid } from 'semantic-ui-react';
import { Table } from '~/components';
import Link from '~/components/Link';
import { usePortfolioContext } from '~/hooks';
import PortfolioSummaryTableStatus from './PortfolioSummaryTableStatus';
import { formatNumber } from '~/utils';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { DealStates } from '~/constants';

export default function PortfolioSummaryTable() {
  const { projectBlobs, portfolioState } = usePortfolioContext();
  const { permissions } = usePermissions();

  const isInvestor = !permissions.isAdmin && permissions.hasPortfolioInvestorAccess;
  const parentProjectBlobs = projectBlobs?.filter((projectBlob: any) => !projectBlob?.project?.parentId);
  const portfolioProjects = (isInvestor || portfolioState?.latestState >= DealStates.IN_DILIGENCE) ? projectBlobs : parentProjectBlobs;

  return (
    <div style={{margin: '0 auto var(--large)'}}>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Project name</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Size (kWdc)</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Budget ($/Wdc)</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Offtake</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Rate ($/kWh)</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Term + escalator</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {portfolioProjects?.map((projects: any) => {
                const { project, projectCosts, projectDisplayValues } = projects;

                return (
                  <Table.Row key={project?.id}>
                    <Table.Cell>
                      <Link 
                        to={`/project/${project.id}`}
                        primary
                        bold
                        underline  
                      >
                        {project.name}
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="center">{formatNumber(projectCosts.sizeKwdc)}</Table.Cell>
                    <Table.Cell textAlign="center">{projectDisplayValues.budget.totals.perWdc}</Table.Cell>
                    <Table.Cell textAlign="center">{projectDisplayValues.projectDetails.offtakeRate.type}</Table.Cell>
                    <Table.Cell textAlign="center">{projectDisplayValues.projectDetails.offtakeRate.rate}</Table.Cell>
                    <Table.Cell textAlign="center">{projectDisplayValues.projectDetails.offtakeRate.term}, {projectDisplayValues.projectDetails.offtakeRate.escalator}</Table.Cell>
                    <Table.Cell textAlign="center">{projectDisplayValues.projectDetails.location}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <PortfolioSummaryTableStatus projectBlob={projects} />
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>          
        </Grid.Column>
      </Grid.Row>
    </div>
  );
}
