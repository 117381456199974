import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Route } from 'react-router-dom';
import { fetchLoggedInUser } from '~/api/user';
import { usePromiseWatcher, useUserContext } from '~/hooks';
import AsyncLoading from '~/components/AsyncLoading';
import TutorialProvider from '~/hooks/providers/TutorialProvider';
import { Sidebar } from './Sidebar';
import { ModalProvider } from '~/hooks/providers/ModalProvider';
import ReactGA from 'react-ga4';
import { storage } from '~/utils';

const SidebarContainer = styled.div`
  position: relative;
  padding-left: var(--sidebar-width);
`;

const AuthRouter = function ({ children }: { children: React.ReactNode }) {
  const {loggedInUser, setLoggedInUser} = useUserContext() as any;
  // Look up logged-in user info
  const {execute, status} = usePromiseWatcher(
    async () => {
      const user = await fetchLoggedInUser();
      setLoggedInUser(user);

      // Analytics
      if (storage.getSpoofedEmail()){
        ReactGA.gtag('set', 'user_properties', { user_id: 'Spoof', user_company: 'Spoof', user_type: 'Spoof' });
      } else {
        ReactGA.gtag('set', 'user_properties', { 
          user_id: loggedInUser?.id, 
          user_company: loggedInUser?.isAdmin 
            ? 'Admin' 
            : (
                loggedInUser?.company?.name ?? 
                loggedInUser?.investor?.company?.name ?? 
                loggedInUser?.installer?.company?.name
              ),
          user_type: loggedInUser?.role?.code 
        });      
      }
    },
    'checking your login status',
    []
  );
  useEffect(execute, []);

  if (loggedInUser?.requiresEmailVerification && !window.location.pathname.includes('/confirm-email')) {
    // Redirect to email verification
    window.location.href = '/confirm-email';
    return null;
  }

  return (
    <>
      <AsyncLoading status={status}/>
      {loggedInUser && children}
    </>
  );
};

const AuthRoute = function ({children, ...props}: any) {
  const { loggedInUser } = useUserContext() as any;

  const showNav = loggedInUser && !loggedInUser.requiresEmailVerification;

  return (
    <Route {...props}>
        <AuthRouter>
          <ModalProvider>
            <TutorialProvider>
              {showNav ? (
                <SidebarContainer>
                  <Sidebar />
                  <main>
                    {children}
                  </main>
                </SidebarContainer>
              ): <main>{children}</main>}
            </TutorialProvider>
          </ModalProvider>
        </AuthRouter>
    </Route>
  );
};

export default AuthRoute;
