import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useUserContext } from '../providers/UserContextProvider';
import { storage } from '~/utils';
import usePageType from '../usePageType';
import { useProjectContext } from '../providers/ProjectContextProvider';
import { usePortfolioContext } from '../providers/PortfolioContextProvider';

// Sends a page view event to Google Analytics (GA4)
export default function useAnalyticsPageView(endpoint: string, title: string) {
   const { loggedInUser } = useUserContext() as any;
   const pageType = usePageType();
   const { project } = useProjectContext();
   const { portfolio } = usePortfolioContext();

   useEffect(() => {
      // Update user properties before sending pageview (sometimes doesn't get re-set often enough when switching between spoofed users and real users)
      if (storage.getSpoofedEmail()){
         ReactGA.gtag('set', 'user_properties', { user_id: 'Spoof', user_company: 'Spoof', user_type: 'Spoof' });
      } else {
         ReactGA.gtag('set', 'user_properties', { 
            user_id: loggedInUser?.id, 
            user_company: loggedInUser?.isAdmin 
              ? 'Admin' 
              : (
                  loggedInUser?.company?.name ?? 
                  loggedInUser?.investor?.company?.name ?? 
                  loggedInUser?.installer?.company?.name
                ),
            user_type: loggedInUser?.role?.code 
         });      
      }
      
      // Send page view based on page type
      if (pageType === 'project'){
         ReactGA.send({ hitType: 'pageview', page: endpoint, title, project_name: `${project.name} (${project.id})` });
      } else if (pageType === 'portfolio'){
         ReactGA.send({ hitType: 'pageview', page: endpoint, title, portfolio_name: `${portfolio.name} (${portfolio.id})` });
      } else if (pageType === 'general'){
         ReactGA.send({ hitType: 'pageview', page: endpoint, title });
      }
   }, []);
}